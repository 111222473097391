/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "checkEmailExists",
            "endpoint": "https://dhdu6wvhe4.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "inviteClient",
            "endpoint": "https://6sayck66x5.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "stripe",
            "endpoint": "https://x56iv9ueu3.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "sendEmail",
            "endpoint": "https://hmudbjt8m6.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "member",
            "endpoint": "https://yuzoba1wk5.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "resendInvite",
            "endpoint": "https://oeta0n2jb2.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "user",
            "endpoint": "https://5bzexmtcpg.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "sendReport",
            "endpoint": "https://aqxz1q5h94.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "deviceChecksCustomOps",
            "endpoint": "https://p3izm34sll.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://fywvmf4tc5gcnpkpnhs2xep4x4.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_vqeEvq7Bi",
    "aws_user_pools_web_client_id": "ujsnj5mqm8r5fnbj6766lbt4q",
    "aws_cognito_identity_pool_id": "ap-southeast-2:493f967e-b6a9-432f-ade5-5d094fff2b66",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
